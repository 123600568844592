.wrap {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 80px 20px;

    @include breakpoint(mobileonly) {
        padding: 40px 20px;
    }
}

.three-cta {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    padding: 80px 0;
    background: $bg-light;
    width: 100%;

    @include breakpoint(mobileonly) {
        flex-direction: column;
    }

    a {
        text-decoration: none;
    }

    .cta {
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 450px;

        @include breakpoint(mobileonly) {
            padding-bottom: 40px;
            margin: 0 auto;
        }

        img {
            width: 100%;
            max-height: 245px;
            object-fit: cover;
        }

        h2 {
            color: $primary;
            margin: 30px 0 10px;

            svg {
                margin-left: 5px;
                height: 25px;
                padding-bottom: 5px;
            }
        }

        &:hover {
            background-color: #ffffff;

            h2 {
                color: $accent;
            }
        }

        .cta-description {
            color: $text;
        }
    }
}

.two-column {
    display: flex;
    flex-wrap: wrap;
    //flex-direction: column;
    .column {
        width: 100%;
    }

    @include breakpoint (tablet) {
        .column {
            width: 50%;
            padding: 20px 0;
        }
    }
}

.fullwidth {
    margin-top: 20px;
    margin-bottom: 20px;

    @include breakpoint(tablet) {
        margin-top: 100px;
        margin-bottom: 100px;
    }
}

img.fullwidth {
    height: 300px;
    object-fit: cover;
}

.fullwidth {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.image {
    img {
        display: block;
        margin: 0 auto;
        padding-bottom: 10px;
    }
    figcaption {
        color: $accent;
    }
}

.right-align {
    float: right;
    padding: 0 0 20px 20px;

    @include breakpoint(laptop) {
        margin-right: -30px;
    }
}

.left-align {
    float: left;
    padding: 0 20px 20px 0;

    @include breakpoint(laptop) {
        margin-left: -30px;
    }
}

.label {
    margin-bottom: 10px;
}

.info {
    font-family: 'Vollkorn', serif;
    color: $primary;
    font-size: 28px;
}

.button {
    color: white;
    background: $primary;
    padding: 15px 20px;
    margin: 10px 0;
    display: inline-block;
    text-decoration: none;
    font-weight: 600;
    border-radius: 10px; 
    -moz-border-radius: 10px; 
    -webkit-border-radius: 10px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; 

    &:hover {
        background: $accent;
    }

    svg {
        margin-left: 10px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease; 
    }

    &:hover svg {
        margin-left: 14px;
    }
}

// TEMPALTE: ABOUT

#locations {
    background: $bg-light;
    padding: 40px 0;
    min-height: 372px;

    .search {
        position: relative;
        color: #aaa;
        font-size: 16px;
      }

    .search input { text-indent: 32px;}
    .search .fa-search { 
    position: absolute;
    top: 14px;
    left: 14px;
    }

    .location-shuffle {
        width: 85%;
        margin: 0 auto;
        
        @include breakpoint(mobileonly) {
            width: 100%;
        }
    }
}

// TEMPLATE: EVENTS

.event {
    margin-bottom: 80px;

    &:last-of-type {
        margin-bottom: 0;
    }
}

// TEMPLATE: LOCATION

.return a {
    color: $primary;
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
}

.location .padded {
    padding: 5%;

    @include breakpoint(mobileonly) {
        padding: 40px 20px;
    }
}

.location img {
    max-width: 100%;
    max-height: 500px;
    width: auto;
    margin: 0 auto;
}

.quick-facts .column {
    margin: 20px 0;

    @include breakpoint(mobileonly) {
        padding: 20px 0;
        margin: 0;
    }
}

.slick-slider>.slick-prev {
    left: 10px;
    z-index: 1;
  }
  .slick-slider>.slick-next {
    right: 10px;
    z-index: 1;
  }