/* Small reset */

    *,
    ::before,
    ::after {
    box-sizing: border-box;
    }

    figure {
    margin: 0;
    padding: 0;
    }

/*
  Shuffle needs either relative or absolute positioning on the container
  It will set it for you, but it'll cause another style recalculation and layout.
  AKA worse performance - so just set it here
 */
.my-shuffle-container {
  position: relative;
  overflow: hidden;
}

.my-sizer-element {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

$picture-gutter: 24px;
$item-height: 300px;
.picture-item {
  height: 300px;
  margin-top: $picture-gutter;

  img {
    display: block;
    width: 100%;
  }
}

@supports (object-fit: cover) {
  .picture-item img {
    max-width: none;
    height: 100%;
    object-fit: cover;
  }
}

figure a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.picture-item--h2 {
  height: ($item-height * 2) + $picture-gutter; /* 2x the height + 1 gutter */
}

.picture-item__inner {
  position: relative;
  height: 100%;
  background: #ffffff;

  a:hover & {
    background: #42592C;
  }
}

.picture-item__details {
  width: 100%;
  padding: 1em;
}

.picture-item__description {
  width: 100%;
  padding: 0 2em 1em 1em;
  margin: 0;
}

.picture-item__title {
  margin-right: 4px;
  font-family: 'Vollkorn', serif;
  font-weight: 400;
  color: #42592C;
  font-size: 18px;

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }

  a:hover & {
    color: #ffffff;
  }
}

.picture-item__tags {
  margin: .4em 0 0 0;
  color: #000;
  font-weight: 500;

  a:hover & {
    color: #ffffff;
  }
}

@media screen and (min-width: 768px) {
  .picture-item--overlay {
    .picture-item__details {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      backdrop-filter: blur(7px);
      color: white;
      overflow: hidden;
    }

    .picture-item__description {
      display: none;
    }
    
    a {
      color: white;
      text-shadow: 0 0 1px black;
    }
  }
}

@media screen and (max-width: 767px) {
  .picture-item {
    height: auto;
    margin-top: 20px;
  }

  .picture-item__details,
  .picture-item__description {
    font-size: 0.875em;
    padding: 0.625em;
  }

  .picture-item__description {
    padding-right: 0.875em;
    padding-bottom: 1.25em;
  }

  .picture-item--h2 {
    height: auto;
  }
}

// Filter styling
// --------------
.filter-label {
  display: block;
  padding: 0;
  margin-top: 0;
  margin-bottom: 4px;
  color: #a3a3a3;
}

.filters-group {
  padding: 0;
  margin: 0 0 4px;
  border: 0;
}

@media screen and (min-width: 768px) {
  .filters-group-wrap {
    display: flex;
    justify-content: space-between;
  }
}

.btn-group {
  width: 100%;
  
  @media screen and (min-width: 768px) {
    width: unset;
  }
}

.btn-group:after,.btn-group:before {
  content: " ";
  display: table
}

.btn-group:after {
  clear: both
}

.btn-group .btn {
  float: left;
  border-radius: 0;
  width: 33.3%;

  @media screen and (min-width: 768px) {
    width: unset;
  }
}

.btn-group .btn:first-child {
  border-radius: 16px 0 0 16px
}

.btn-group .btn:not(:first-child) {
  margin-left: -1px
}

.btn-group .btn:last-child {
  border-radius: 0 16px 16px 0
}

.btn-group label.btn input[type=radio] {
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none
}

.btn {
  display: inline-block;
  padding: .75em 1em;
  text-align: center;
  border-radius: 16px;
  border: 1px solid #42592C;
  color: #42592C;
  font-size: 1rem;
  background-color: rgba(52,73,94,0);
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none
}

@media (-moz-touch-enabled:0),(pointer: fine) {
  .btn:hover {
    color:#fff;
    text-decoration: none;
    background-color: #42592C
  }
}

.btn:focus {
  outline-width: 0;
}

.btn.active,.btn:active {
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: #fff;
  background-color: #42592C
}

.btn:disabled {
  cursor: not-allowed;
  opacity: .7;
  color: #42592C;
  background-color: rgba(52,73,94,0)
}

.btn--primary {
  color: #ffffff;
  background-color: #42592C;
}

@media (-moz-touch-enabled:0),(pointer: fine) {
  .btn--primary:hover {
    background-color:#ffffff;
    color: #42592C;
  }
}

.btn--primary.active,.btn--primary:active {
  background-color: #ffffff;
  color: #42592C;
  border-color: #42592C;
}

.btn--primary:disabled {
  color: #42592C;
  background-color: rgba(52,152,219,0)
}

@media screen and (max-width: 767px) {
  .btn {
    font-size:.875rem
  }
}

// Textfield
// ----------------
.textfield {
  -webkit-appearance: none;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 40px;
  padding: .75em 1em;
  font-size: 1rem;
  color: #000000;
  transition: .15s
}

.textfield::-webkit-input-placeholder {
  color: #a3a3a3;
  transition: .15s
}

.textfield:-ms-input-placeholder {
  color: #a3a3a3;
  transition: .15s
}

.textfield::-ms-input-placeholder {
  color: #a3a3a3;
  transition: .15s
}

.textfield::placeholder {
  color: #a3a3a3;
  transition: .15s
}

.textfield:hover {
  outline-width: 0;
  color: #000000;
  border-color: #4E4E4E;
}

.textfield:hover::-webkit-input-placeholder {
  color: #000000
}

.textfield:hover:-ms-input-placeholder {
  color: #000000
}

.textfield:hover::-ms-input-placeholder {
  color: #000000
}

.textfield:hover::placeholder {
  color: #000000
}

.textfield:focus {
  outline-width: 0;
  border-color: #000000
}

.textfield:focus::-webkit-input-placeholder {
  color: #000000
}

.textfield:focus:-ms-input-placeholder {
  color: #000000
}

.textfield:focus::-ms-input-placeholder {
  color: #000000
}

.textfield:focus::placeholder {
  color: #000000
}

// bootstrap3-like grid
// -----------
.container {
  padding-left: 3.5%;
  padding-right: 3.5%;
}

.container:after,
.container:before {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

.row {
  margin-left: auto;
  margin-right: auto;
}

.row:after,
.row:before {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.row .row {
  margin-left: -8px;
  margin-right: -8px;
}

.flex-centered {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
}

/* Ensure images take up the same space when they load */
/* https://vestride.github.io/Shuffle/images */
.aspect {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
}

.aspect__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.aspect--16x9 {
  padding-bottom: 56.25%;
}

// bootstrap3-like grid
.col-1\@xs,
.col-2\@xs,
.col-3\@xs,
.col-4\@xs,
.col-5\@xs,
.col-6\@xs,
.col-1\@sm,
.col-2\@sm,
.col-3\@sm,
.col-4\@sm,
.col-5\@sm,
.col-6\@sm,
.col-7\@sm,
.col-8\@sm,
.col-9\@sm,
.col-10\@sm,
.col-11\@sm,
.col-12\@sm,
.col-1\@md,
.col-2\@md,
.col-3\@md,
.col-4\@md,
.col-5\@md,
.col-6\@md,
.col-7\@md,
.col-8\@md,
.col-9\@md,
.col-10\@md,
.col-11\@md,
.col-12\@md {
  position: relative;
  box-sizing: border-box;
  min-height: 1px;
  padding-left: 8px;
  padding-right: 8px;
}

.col-1\@xs,
.col-2\@xs,
.col-3\@xs,
.col-4\@xs,
.col-5\@xs,
.col-6\@xs {
  float: left;
}

.col-1\@xs {
  width: 16.66667%;
}

.col-2\@xs {
  width: 33.33333%;
}

.col-3\@xs {
  width: 50%;
}

.col-4\@xs {
  width: 66.66667%;
}

.col-5\@xs {
  width: 83.33333%;
}

.col-6\@xs {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .col-1\@sm,
  .col-2\@sm,
  .col-3\@sm,
  .col-4\@sm,
  .col-5\@sm,
  .col-6\@sm,
  .col-7\@sm,
  .col-8\@sm,
  .col-9\@sm,
  .col-10\@sm,
  .col-11\@sm,
  .col-12\@sm {
    float: left;
  }

  .col-1\@sm {
    width: 8.33333%;
  }

  .col-2\@sm {
    width: 16.66667%;
  }

  .col-3\@sm {
    width: 25%;
  }

  .col-4\@sm {
    width: 33.33333%;
  }

  .col-5\@sm {
    width: 41.66667%;
  }

  .col-6\@sm {
    width: 50%;
  }

  .col-7\@sm {
    width: 58.33333%;
  }

  .col-8\@sm {
    width: 66.66667%;
  }

  .col-9\@sm {
    width: 75%;
  }

  .col-10\@sm {
    width: 83.33333%;
  }

  .col-11\@sm {
    width: 91.66667%;
  }

  .col-12\@sm {
    width: 100%;
  }

  .container {
    padding-left: 7%;
    padding-right: 7%;
  }

  .row {
    max-width: 1200px;
  }

  .row-sm {
    max-width: 800px;
  }
}

@media screen and (min-width: 1024px) {
  .col-1\@md,
  .col-2\@md,
  .col-3\@md,
  .col-4\@md,
  .col-5\@md,
  .col-6\@md,
  .col-7\@md,
  .col-8\@md,
  .col-9\@md,
  .col-10\@md,
  .col-11\@md,
  .col-12\@md {
    float: left;
  }

  .col-1\@md {
    width: 8.33333%;
  }

  .col-2\@md {
    width: 16.66667%;
  }

  .col-3\@md {
    width: 25%;
  }

  .col-4\@md {
    width: 33.33333%;
  }

  .col-5\@md {
    width: 41.66667%;
  }

  .col-6\@md {
    width: 50%;
  }

  .col-7\@md {
    width: 58.33333%;
  }

  .col-8\@md {
    width: 66.66667%;
  }

  .col-9\@md {
    width: 75%;
  }

  .col-10\@md {
    width: 83.33333%;
  }

  .col-11\@md {
    width: 91.66667%;
  }

  .col-12\@md {
    width: 100%;
  }
}
