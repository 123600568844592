footer {
    background: $primary;
    color: white;
    padding: 50px 20px;
    display: flex;
    flex-direction: row;

    @include breakpoint(mobileonly) {
        flex-direction: column;
    }

    .copyright {
        flex-grow: 1;
        font-size: 12px;

        @include breakpoint(mobileonly) {
            padding-bottom: 30px;
        }
    }

    .logos {

        @include breakpoint(mobileonly) {
            align-self: center;
        }

        img {
            max-height: 40px;

            &:first-of-type {
                padding-right: 40px;

                @include breakpoint(mobileonly) {
                    padding-bottom: 20px;
                }
            }
        }
    }
}