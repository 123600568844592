@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600|Vollkorn&display=swap');

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

p {
  line-height: 1.5em;
}

h1, h2, h3, h4 {
    font-family: 'Vollkorn', serif;
    font-weight: 400;
    color: $accent;
}

h1 {
  font-size: 45px;
  padding-bottom: 30px;
  border-bottom: 2px solid #E6E6E6;
}

h2 {
  font-size: 32px;
}

a {
  color: $accent;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

ul {
  padding-bottom: 20px;
}

li {
  line-height: 1.5em;
}

blockquote {
  color: $primary;
  font-family: Vollkorn, serif;
  font-size: 20px;
  font-style: italic;
}

.attribution {
  padding-left: 40px;
  font-weight: bold;
  color: #4c4c4c;
}

.fa-external-link {
  font-size: .8em;
}