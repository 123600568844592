header {
    display: flex;
    justify-content: center;

    nav {
        position: absolute;
        top: 0;
        z-index: 10;
        width: 90%;
        height: 60px;
        margin-top: 40px;
        align-content: center;
        display: flex;
        flex-direction: row;

        @include breakpoint(tablet) {
            margin-top: 70px;
        }

        .logo {
            flex-grow: 1;

            svg {
                max-width: 80%;
                width: 400px;

                @include breakpoint(mobileonly) {
                    width: 100%;
                }
            }
        }

        .links {
            display: none;
            
            a {
                color: white;
                text-decoration: none;
                font-weight: 600;
                height: fit-content;
                margin-right: 60px;
                padding-bottom: 5px;

                &:hover {
                    text-decoration: none;
                    border-bottom: 2px solid white;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }

            @include breakpoint(tablet) {
                display: inherit;
            }
        }

        /* The Overlay (background) */
        .overlay {
            /* Height & width depends on how you want to reveal the overlay (see JS below) */   
            height: 0;
            width: 100%;
            position: fixed; /* Stay in place */
            z-index: 1; /* Sit on top */
            left: 0;
            top: 0;
            background-color: rgb(0,0,0); /* Black fallback color */
            background-color: rgba(0,0,0, 0.9); /* Black w/opacity */
            overflow-x: hidden; /* Disable horizontal scroll */
            transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
        }
        
        /* Position the content inside the overlay */
        .overlay-content {
            position: relative;
            top: 25%; /* 25% from the top */
            width: 100%; /* 100% width */
            text-align: center; /* Centered text/links */
            margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
        }
        
        /* The navigation links inside the overlay */
        .overlay a {
            padding: 8px;
            text-decoration: none;
            font-size: 36px;
            color: #ffffff;
            display: block; /* Display block instead of inline */
            transition: 0.3s; /* Transition effects on hover (color) */
        }
        
        /* When you mouse over the navigation links, change their color */
        .overlay a:hover, .overlay a:focus {
            color: #c4c4c4;
        }
        
        /* Position the close button (top right corner) */
        .overlay .closebtn {
            position: absolute;
            top: 20px;
            right: 45px;
            font-size: 60px;
        }
        
        /* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
        @media screen and (max-height: 450px) {
            .overlay a {font-size: 20px}
            .overlay .closebtn {
            font-size: 40px;
            top: 15px;
            right: 35px;
            }
        }

        .menu {
            @include breakpoint(tablet) {
                display: none;
            }

            svg {
                font-size: 40px;
                color: white;
            }
        }
    }
    .hero {
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
    }
    .hero::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom,  rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 99%), url(/assets/images/locations/Fountain.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        filter: brightness(90%);
    }
    .hero-content {
        position: relative; 
        margin: 0.625rem;
        display: flex;
        flex-grow: 1;

        h1 {
            color: white;
            align-self: center;
            font-size: 60px;
            padding: 0 20px;
            border: none;
            text-shadow: 0 0 7px rgba(0,0,9,.9);

            @include breakpoint(mobileonly) {
                font-size: 45px;
            }
        }
    }
    .hero-arrow {
        position: relative;
        transform: translateY(-50%);
        
        a {
            color: white;
            font-size: 2em;
        }
    }
}

.bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-5px);
    }
}

.banner {
    display: inline-block;
    background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 99%);
    width: 100%;

    img {
        position: relative;
        display: block;
        width: 100%;
        height: 300px;
        object-fit: cover;
        z-index: -1;
        filter: brightness(80%);
        background-color: #000;

        @include breakpoint(tablet) {
            height: 500px;
        }
    }
}